
// Fonts
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,600);

// Variables
@import "variables";

// Utilities
@import "utility";

// Bootstrap
@import "bootstrap";

// utilities
.d-inline {
	display: inline;
}
.italic{
	font-style: italic;
}
@media (min-width: 768px) {
	.float-md-right{
		float: right;
	}
}
// tooltip 
.tooltipped {
	position: relative
}

.tooltipped:after {
	position: absolute;
	z-index: 1000000;
	display: none;
	padding: 5px 8px;
	font: normal normal 11px/1.5 Helvetica,arial,nimbussansl,liberationsans,freesans,clean,sans-serif,"Segoe UI Emoji","Segoe UI Symbol";
	color: #fff;
	text-align: center;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: break-word;
	white-space: pre;
	pointer-events: none;
	content: attr(aria-label);
	background: rgba(0,0,0,0.8);
	border-radius: 3px;
	-webkit-font-smoothing: subpixel-antialiased
}

.tooltipped:before {
	position: absolute;
	z-index: 1000001;
	display: none;
	width: 0;
	height: 0;
	color: rgba(0,0,0,0.8);
	pointer-events: none;
	content: "";
	border: 5px solid transparent
}

.tooltipped:hover:before,
.tooltipped:hover:after,
.tooltipped:active:before,
.tooltipped:active:after,
.tooltipped:focus:before,
.tooltipped:focus:after {
	display: inline-block;
	text-decoration: none
}

.tooltipped-s:after {
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
	margin-top: 5px
}

.tooltipped-s:before {
	top: auto;
	left: 50%;
	transform: translateX(-50%);
	bottom: -5px;
	margin-right: -5px;
	border-bottom-color: rgba(0,0,0,0.8)
}

.copyable{
	color: $brand-primary;
	cursor: pointer;
}
.overflow-ellipsis{
	text-overflow: ellipsis;
	overflow: hidden;
}

// js hiding the options
.j-options--hidden{
	display: none;
}

// logo
.nav-logo{
	width: 100px;
	padding-top: 0.5em;
	@media (max-width: 768px) {
		padding: 0.5em;
		padding-top: 1em;
	}
}
.navbar-kickto{
	background-color: $lb-black;
	.navbar-brand{
		height: auto;
	}
	.navbar-nav > li > a{
		color: #f2f2f2;
		&:hover{
			color: $lb-yellow;
		}
	}
}
.navbar-nav.navbar-right li:not(.dropdown) a {
    padding-top: 1.5em;
}


// home
.landing-page{
	background-color: #fff;
	h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
		text-rendering: optimizelegibility;
		color: $lb-black;
		line-height: 1.4;
		font-weight: bold;
		margin: 0.5em auto 0.3em;
	}
	.title-no-style{
		font-weight: normal;
	}
	h1, .h1{
		font-size: 27px;
		@media #{$s}{
			font-size: 32px;
		}
		@media #{$m}{
			font-size: 48px;
		}
		@media #{$l}{
			font-size: 64px;
		}
	}
	h2, .h2{
		font-size: 26px;
		@media #{$s}{
			font-size: 32px;
		}
		@media #{$m}{
			font-size: 36px;
		}
		@media #{$b}{
			font-size: 48px;
		}
	}

	h3, .h3 {
    font-size: 21px;
    @media #{$m}{
    	font-size: 24px;
    }
    @media #{$b}{
    	font-size: 28px;
    }
	}
	h4, .h4 {
    font-size:18px;
    @media #{$s}{
    	font-size: 21px;
    }
    @media #{$m}{
    	font-size: 24px;
    }
	}
	.navbar{
		background-color: transparent;
		border-bottom: 0;
		position: absolute;
	}
	.navbar-collapse{
		@media screen and (max-width: 767px) {
			background-color: $lb-black;
		}
	}
	.white{
		color: #fff;
	}
	.bigger {
    font-size: 21px;
    @media #{$m}{
    	font-size: 24px;
    }
    @media #{$b}{
    	font-size: 32px;
    }
	}
	img:not(.nav-logo, .spark-nav-profile-photo){
		display: block;
		width: auto;
		height: auto;
	}
	.fourcol{
		@media #{$m} {
			float: left;
			width: 33.33333333%;
		}
	}
	.navbar-link.navbar-link--button{
		position: relative;
		&:after {
			@media screen and (min-width: 768px) {
				position: absolute;
		    width: 100%;
		    height: 2.25em;
		    border: 2px solid $blue;
		    bottom: 1em;
		    left: 0;
		    z-index: 1;
		    content: '';
		    border-radius: 10em;
			}
		}
	}
}
.hero{
	background-image: url('/images/hero.jpg');
	background-size: cover;
	background-position: 50% 100%;
	background-repeat: no-repeat;
	min-height: calc(100vh - 50px);
	padding: 20vh 1em;
	@media #{$m} {
		padding: 30vh 1em;
	}
}
.hero-inner{
	max-width: 500px;
	margin: auto;
	@media #{$m}{
		max-width: 1400px;
	}
}
.hero-headline{
	@media #{$m} {
		width: 75%;
		margin-left: 0 !important;
	}
}

.button{
	margin-top: 1em;
	display: inline-block;
	padding: 0.6em 1.9em;
	text-align: center;
	background-color: $blue;
	color: #fff;
	border: 2px solid $blue;
	line-height: 1.2;
	font-size: 1.2em;
	letter-spacing: 0.1em;
	-webkit-appearance: none;
	font-weight: bold;
	transition: ease all 300ms;
	text-decoration: none;
	border-radius: 1em;
	&:hover{
		background-color: #fff;
		text-decoration: none;
		color: $blue;
	}
}
.steps-section{
	padding: 1em;
}
.circle-link{
	max-width: 50px;
	margin: auto;
	@media #{$m} {
		max-width: 155px;
	}
}
.steps{
	max-width: 1100px;
	margin: 1em auto;
	@media #{$m} {
		text-align: center;
	}
	@media #{$m2}{
		max-width: 500px;
		margin: auto;
	}
}
.step{
	padding: 1em;
	@media #{$m2} {
		padding: 1em 0;
		img{
			float: left;
			width: 30% !important;
		}
		p {
			float: right;
			width: 70%;
			padding-left: 1em;
			padding-top: 1em;
		}
	}
	&:nth-child(2){
		img{
			max-width: 230px;
		}
	}
	img{
		max-width: 175px;
		margin: auto;
	}
}
.link-creation-heading{
	background-color: $lb-black;
	padding: 5% 1em;
	text-align: center;
	h2{
		max-width: 1200px;
	}
}
.link-items{
	max-width: 650px;
	margin: auto;
	margin-top: 3%;
	padding: 1em 0;
	text-align: center;
	@media #{$m} {
		padding: 1em;
	}
}
.link-item{
	float: left;
	width: 33.33333333%;
	padding: 0.5em;
	@media #{$m} {
		padding: 1em;
	}
	img{
		max-width: 70px;
		margin: auto;
		@media #{$m}{
			max-width: 110px;
		}
	}
}
.link-creator{
	max-width: 365px;
	margin: auto;
	padding-right: 5vh;
	@media #{$xs} {
		padding-right: 100px;
	}
	@media #{$m2} {
		max-width: 50%;
	}
}

.created-link{
	background-color: $lb-black;
	border-color: $lb-black;
	margin: 1.5em auto;
	background-image: url('/images/Kicktoillustrations-13.svg');
	background-repeat: no-repeat;
	background-position: 1em 50%;
	background-size: 2em;
	padding: 1em;
	padding-left: 3.5em;
	border-radius: 10em;
	text-decoration: none;
	&:active,
	&:focus,
	&:hover{
		color: $lb-black;
		text-decoration: none;
	}
	@media #{$m} {
		font-size: 2em;
	}
}

.cta-section{
	padding: 1em;
	padding-bottom: 5em;
	background-color: $lb-black;
	margin-top: 1em;
	h2{
		margin: 0.5em auto 0.75em;
	}
}
.cta-inner{
	max-width: 1175px;
	margin: auto;
	background-color: #fff;
	border: 0.25em solid $lb-yellow;
	border-radius: 0.25em;
	padding: 1em;
	@media #{$m} {
		border: 0.5em solid $lb-yellow;
		border-radius: 0.5em;
	}
	h2{
		margin-bottom: 0.25em;
	}
}
.main-cta{
	max-width: 900px;
	margin: auto;
	display: block;
	margin-bottom: -2em;
	margin-top: 1em;
	font-size: 21px;
  @media #{$m}{
  	font-size: 24px;
  }
  @media #{$b}{
  	font-size: 28px;
  }
  @media #{$l}{
  	font-size: 34px;
  }
}

.about-launchboom{
	margin: 5% auto;
	padding: 2.5% 5%;
	max-width: 1200px;
	box-shadow: 0 0 3em rgba(0, 0, 0, 0.1);
	border-radius: 1em;
	text-align: center;
	h3{
		max-width: 1000px;
		margin: 1em auto;
		span{
			color: $lb-yellow;
		}
	}
}
.launchboom-logo{
	margin: auto;
	max-width: 75%;
	@media #{$m} {
		max-width: 600px;
	}
}
.launchboom-logos{
	max-width: 750px;
	margin: auto;
	img{
		min-width: 50%;
	}
}
.sixcol{
	float: left;
	width: 50%;
	padding: 1em;
	img{
		width: 100% !important;
		margin: auto;
		&.igg-logo{
			max-width: 300px !important;
		}
	}
	p{
		margin-top: 1em;
	}
}








