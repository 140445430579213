//////////padding and margin classes////////////
//////////padding and margin classes////////////
//////////padding and margin classes////////////
.ma{
  margin: auto;
}
.m0{
  margin: 0;
}
.mt0{
  margin-top: 0;
}
.mb0{
  margin-bottom: 0;
}
.m05{
  margin: 0.5em;
}
.mt05{
  margin-top: 0.5em;
}
.mb05{
  margin-bottom: 0.5em;
}
.m1{
  margin: 1em;
}
.mt1{
    margin-top: 1em;
}
.mb1{
    margin-bottom: 1em;
}
.mt2{
  margin-top: 2em;
}
.mb2{
  margin-bottom: 2em;
}




.p05{
  padding: 0.5em;
}
.pt05{
  padding-top: 0.5em;
}
.pb05{
  padding-bottom: 0.5em;
}
.p1{
  padding: 1em;
}
.pt1{
  padding-top: 1em;
}
.pb1{
  padding-bottom: 1em;
}
.p2{
  padding: 2em;
}
.pt2{
  padding-top: 2em;
}
.pb2{
  padding-bottom: 2em;
}
.pb0{
  padding-bottom: 0;
}
.pt0{
  padding-top: 0;
}

////////// layout classes////////////
////////// layout classes////////////
////////// layout classes////////////
.mw-750{
  max-width: 750px;
  margin: auto;
}

//responsive @media #{$m}{} (in an element)
$xs: "only screen and (min-width : 481px)";
$s:  "only screen and (min-width : 638px)";
$m:  "only screen and (min-width : 868px)";
$b:  "only screen and (min-width : 1047px)";
$l:  "only screen and (min-width : 1201px)";
$xl: "only screen and (min-width : 1400px)";


$xs2: "only screen and (max-width : 480px)";
$s2:  "only screen and (max-width : 637px)";
$m2:  "only screen and (max-width : 867px)";
$b2:  "only screen and (max-width : 1046px)";
$l2:  "only screen and (max-width : 1200px)";


// ********************
// RESPONSIVE SHOWING/HIDING STYLES
// ********************
.from-xs-up, .from-s-up, .from-m-up, .from-b-up, .from-l-up{
  display: none !important;
}


.from-xs-down, .from-s-down, .from-m-down, .from-b-down, .from-l-down{
  display: block;
}
@media #{$xs}{
  .from-xs-up{
    display: block !important;
  }

  .from-xs-down{
    display: none;
  }
}
@media #{$s}{
  .from-s-up{
    display: block !important;
  }

  .from-s-down{
    display: none;
  }
}
@media #{$m}{
  .from-m-up{
    display: block !important;
  }

  .from-m-down{
    display: none;
  }
}
@media #{$b}{
  .from-b-up{
    display: block !important;
  }

  .from-b-down{
    display: none;
  }
}
@media #{$l}{
  .from-l-up{
    display: block !important;
  }

  .from-l-down{
    display: none;
  }
}



